import React from "react";
import "./BudgetSettings.css";
const BudgetSettings = (props) => {
  
  return (
    <div className="budget-settings-main-container">
      <div className="budget-settings-breadcrumb">
        <p>Settings</p>
        <p> &gt; </p>
        <p>Budget Settings</p>
      </div>
      <div className="budget-settings-title-div">
        <p>How to change Budget settings in Best Budget</p>
      </div>
      <div className="budget-settings-section-container1">
        <div className="budget-settings-section-container1-description">
          <p>
            1. Click on the menu icon on the left side of the budget screen.
            Then, select the 'Budget Settings' option.
          </p>
        </div>
        <div className="budget-settings-section-container1-img1-div">
          <div className="budget-settings-section-container1-img1"></div>
        </div>
      </div>
      <div className="budget-settings-section-container2">
        <div className="budget-settings-section-container2-description">
          <p>
            2. Here, you can adjust various settings such as currency, number
            format and date format. 
          </p>
          <p>3. Make the desired changes to the settings
            by clicking on the respective field to edit or change it.</p>
        </div>
        <div className="budget-settings-section-container2-img1-div">
          <div className="budget-settings-section-container2-img1"></div>
        </div>
      </div>
      <div className="budget-settings-section-container3">
        
        <div className="budget-settings-section-container3-img1-div">
          <div className="budget-settings-section-container3-img1"></div>
        </div>
      </div>

      <div className="budget-settings-section-container4">
        
        <div className="budget-settings-section-container4-img1-div">
          <div className="budget-settings-section-container4-img1"></div>
        </div>
      </div>
      <div className="budget-settings-section-container5">
        
        <div className="budget-settings-section-container5-img1-div">
          <div className="budget-settings-section-container5-img1"></div>
        </div>
      </div>
      
      <div className="budget-settings-section-container6">
        <div className="budget-settings-section-container6-description">
          <p>
          4. After making the desired changes, click on the 'Save' button to save the settings.
          </p>
        </div>
        <div className="budget-settings-section-container6-img1-div">
          <div className="budget-settings-section-container6-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default BudgetSettings;
