import React from "react";
import "./TransferAllocation.css";
const TransferAllocation = (props) => {
 
  return (
    <div className="transfer-allocation-main-container">
      <div className="transfer-allocation-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Transfer Allocation</p>
      </div>
      <div className="transfer-allocation-title-div">
        <p>Transfer amount from one category to another</p>
      </div>
      <div className="transfer-allocation-section-container1">
        <div className="transfer-allocation-section-container1-description">
          <p>
            1. Locate and click on the category field associated with the amount
            you wish to transfer.
          </p>
        </div>
        <div className="transfer-allocation-section-container1-img1-div">
          <div className="transfer-allocation-section-container1-img1"></div>
        </div>
      </div>
      <div className="transfer-allocation-section-container2">
        <div className="transfer-allocation-section-container2-description">
          <p>2. Click on the transfer option.</p>
        </div>
        <div className="transfer-allocation-section-container2-img1-div">
          <div className="transfer-allocation-section-container2-img1"></div>
        </div>
      </div>
      <div className="transfer-allocation-section-container3">
        <div className="transfer-allocation-section-container3-description">
          <p>3. Enter the amount you intend to transfer from this category.</p>
          <p>
            4. Click on the category field to choose the category where you want
            to transfer the amount.
          </p>
        </div>
        <div className="transfer-allocation-section-container3-img1-div">
          <div className="transfer-allocation-section-container3-img1"></div>
        </div>
      </div>
      <div className="transfer-allocation-section-container4">
        <div className="transfer-allocation-section-container4-description">
          <p>
            5. From the available categories, select the desired category or
            create a new category to transfer the amount to. Once you've
            selected the destination category, click on the transfer button to
            complete the transaction.
          </p>
        </div>
        <div className="transfer-allocation-section-container4-img1-div">
          <div className="transfer-allocation-section-container4-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default TransferAllocation;
