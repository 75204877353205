import React from "react";
import "./AllocBasedOnPrevStats.css";
const AllocBasedOnPrevStats = (props) => {
  
  return (
    <div className="alloc-based-on-prev-stats-main-container">
      <div className="alloc-based-on-prev-stats-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Allocate based on past statistics</p>
      </div>
      <div className="alloc-based-on-prev-stats-title-div">
        <p>Allocate based on past statistics in Best Budget</p>
      </div>
      <div className="alloc-based-on-prev-stats-section-container1">
        <div className="alloc-based-on-prev-stats-section-container1-description">
          <p>
            1. Locate and click on the category field to you wish to allocate
            amount.
          </p>
        </div>
        <div className="alloc-based-on-prev-stats-section-container1-img1-div">
          <div className="alloc-based-on-prev-stats-section-container1-img1"></div>
        </div>
      </div>
      <div className="alloc-based-on-prev-stats-section-container2">
        <div className="alloc-based-on-prev-stats-section-container2-description">
          <p>
            2. Choose from the provided options such as 'Average Spent',
            'Allocated Last Month', or 'Spent Last Month' to allocate the amount
            the selected category based on previous statistics.
          </p>
        </div>
        <div className="alloc-based-on-prev-stats-section-container2-img1-div">
          <div className="alloc-based-on-prev-stats-section-container2-img1"></div>
        </div>
      </div>
      <div className="alloc-based-on-prev-stats-section-container3">
        <div className="alloc-based-on-prev-stats-section-container3-description">
          <p>3. Click on the 'Allocate' option to confirm and execute the allocation.</p>
          
        </div>
        <div className="alloc-based-on-prev-stats-section-container3-img1-div">
          <div className="alloc-based-on-prev-stats-section-container3-img1"></div>
        </div>
      </div>
     
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AllocBasedOnPrevStats;
