import React from "react";
import "./ChangeEmail.css";
const ChangeEmail = (props) => {
 
  return (
    <div className="change-email-main-container">
      <div className="change-email-breadcrumb">
        <p>Profile</p>
        <p> &gt; </p>
        <p>Change Email</p>
      </div>
      <div className="change-email-title-div">
        <p>How to change an email in Best Budget</p>
      </div>
      <div className="change-email-section-container1">
        <div className="change-email-section-container1-description">
          <p>
            1. Click on the menu option located on the top right corner of the
            Dashboard. Then, select the 'Settings' option.
          </p>
        </div>
        <div className="change-email-section-container1-img1-div">
          <div className="change-email-section-container1-img1"></div>
        </div>
      </div>
      <div className="change-email-section-container2">
        <div className="change-email-section-container2-description">
          <p>
            2. Click on the Email field to bring it into edit mode for updating
            the email address.
          </p>
        </div>
        <div className="change-email-section-container2-img1-div">
          <div className="change-email-section-container2-img1"></div>
        </div>
      </div>
      <div className="change-email-section-container3">
        <div className="change-email-section-container3-description">
          <p>
            3. Enter the new email address into the field and click on Úpdate'
            button.{" "}
          </p>
        </div>
        <div className="change-email-section-container3-img1-div">
          <div className="change-email-section-container3-img1"></div>
        </div>
      </div>
      <div className="change-email-section-container4">
        <div className="change-email-section-container4-description">
          <p>
            4. Tap on the 'Generate' button to receive a one-time password
            (OTP). Check your email inbox for the OTP.
          </p>
          
        </div>
        <div className="change-email-section-container4-img1-div">
          <div className="change-email-section-container4-img1"></div>
        </div>
      </div>
      <div className="change-email-section-container5">
        <div className="change-email-section-container5-description">
          <p>
            5. Enter the OTP received in your email into the provided field.
            After entering the OTP, click on the 'Verify' button to update the
            email address.
          </p>
        </div>
        <div className="change-email-section-container5-img1-div">
          <div className="change-email-section-container5-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default ChangeEmail;
