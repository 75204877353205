import React from "react";
import "./RearrangeCategories.css";
const RearrangeCategories = (props) => {

  return (
    <div className="rearrange-categories-main-container">
      <div className="rearrange-categories-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Re-Arrange Categories</p>
      </div>
      <div className="rearrange-categories-title-div">
        <p>Rearranging categories within the category group in Best Budget</p>
      </div>
      <div className="rearrange-categories-section-container1">
        <div className="rearrange-categories-section-container1-description">
          <p>
            1. On your Best Budget App, open Budget page and tap on “Manage
            Categories” icon located on the top right corner.
          </p>
        </div>
        <div className="rearrange-categories-section-container1-img1-div">
          <div className="rearrange-categories-section-container1-img1"></div>
        </div>
      </div>
      <div className="rearrange-categories-section-container2">
        <div className="rearrange-categories-section-container2-description">
          <p>2. Locate the desired category you wish to rearrange.</p>
          <p>
            3. Click on the rearrange icon positioned towards the right of the
            category.
          </p>
          <p>
            4. Hold and drag the category to the desired position within the
            same category group.
          </p>
        </div>
        <div className="rearrange-categories-section-container2-img1-div">
          <div className="rearrange-categories-section-container2-img1"></div>
        </div>
      </div>
      <div className="rearrange-categories-section-container3">
        <div className="rearrange-categories-section-container3-description">
          <p>5. Alternatively, you can also rearrange the category groups.</p>
          <p>
            6. Click and hold on the category group you wish to rearrange.
          </p>
          <p>7. All the categories of all groups will be minimized. </p>
          <p>8. Move the category group to the desired position.</p>
        </div>
        <div className="rearrange-categories-section-container3-img1-div">
          <div className="rearrange-categories-section-container3-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default RearrangeCategories;
