import React from "react";
import "./SpendAnalysis.css";
const SpendAnalysis = (props) => {
  
  return (
    <div className="spend-analysis-main-container">
      <div className="spend-analysis-breadcrumb">
        <p>Reports</p>
        <p> &gt; </p>
        <p>Spending Analysis</p>
      </div>
      <div className="spend-analysis-title-div">
        <p>Spending Analysis report in Best Budget</p>
      </div>
      <div className="spend-analysis-section-container1">
        <div className="spend-analysis-section-container1-description">
          <p>
            1. Tap on the 'More' icon in the navigation bar and select the
            'Reports' icon.
          </p>
        </div>
        <div className="spend-analysis-section-container1-img1-div">
          <div className="spend-analysis-section-container1-img1"></div>
        </div>
      </div>
      <div className="spend-analysis-section-container2">
        <div className="spend-analysis-section-container2-description">
          <p>
            2. Click on the desired category group to open the spending analysis
            for categories associated with that particular category group.
          </p>
        </div>
        <div className="spend-analysis-section-container2-img1-div">
          <div className="spend-analysis-section-container2-img1"></div>
        </div>
      </div>
      <div className="spend-analysis-section-container3">
        <div className="spend-analysis-section-container3-description">
          <p>
            3. Click on the 'Next' icon to move to the categories of the next
            category group.
          </p>
          <p>
            {" "}
            4.Click on 'All groups' to switch back to Spending analysis of all
            category groups.
          </p>
        </div>
        <div className="spend-analysis-section-container3-img1-div">
          <div className="spend-analysis-section-container3-img1"></div>
        </div>
      </div>
      <div className="spend-analysis-title-div2">
        <p>Filters in Category groups</p>
      </div>
      <div className="spend-analysis-section-container4">
        <div className="spend-analysis-section-container4-description">
          <p>
            1. To open filters for all categories in all category groups, click
            on the filters icon.
          </p>
        </div>
        <div className="spend-analysis-section-container4-img1-div">
          <div className="spend-analysis-section-container4-img1"></div>
        </div>
      </div>
      <div className="spend-analysis-section-container5">
        <div className="spend-analysis-section-container5-description">
          <p>
            2. By default, 'Accounts' is opened with 'Select All' filters. Click
            on 'Select None' to unselect all accounts or uncheck specific
            accounts as needed.
          </p>
        </div>
        <div className="spend-analysis-section-container5-img1-div">
          <div className="spend-analysis-section-container5-img1"></div>
        </div>
      </div>
      <div className="spend-analysis-section-container6">
        <div className="spend-analysis-section-container6-description">
          <p>
            3. By default, 'Year to Date (YTD)' is selected. You can select any
            other date filter if required.
          </p>
          <p>
            4. For Date range option, the default start date is given, and you
            can make changes as needed.
          </p>
          <p>
            5. Click on 'reset' button to reset all the filters. Click on
            'Apply' button to apply the filters.
          </p>
        </div>
        <div className="spend-analysis-section-container6-img1-div">
          <div className="spend-analysis-section-container6-img1"></div>
        </div>
      </div>
      <div className="spend-analysis-title-div2">
        <p>Filters in Categories</p>
      </div>
      <div className="spend-analysis-section-container7">
        <div className="spend-analysis-section-container7-description">
          <p>
            1. To open filters for all categories in a category group, click on
            the filters icon.
          </p>
        </div>
        <div className="spend-analysis-section-container7-img1-div">
          <div className="spend-analysis-section-container7-img1"></div>
        </div>
      </div>
      <div className="spend-analysis-section-container8">
        <div className="spend-analysis-section-container8-description">
          <p>
            2. By default, 'Accounts' is opened with 'Select All' filters. Tap
            on Categories or Time frame to move to their respective filters.
          </p>
          <p>
            3. Click on 'Select None' to unselect all accounts or uncheck
            specific accounts as needed.
          </p>
        </div>
        <div className="spend-analysis-section-container8-img1-div">
          <div className="spend-analysis-section-container8-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default SpendAnalysis;
