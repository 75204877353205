import React from "react";
import "./AddRecurrTransaction.css";
const AddRecurrTransaction = (props) => {
  
  return (
    <div className="add-recurr-transaction-main-container">
      <div className="add-recurr-transaction-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Add a Recurring Transaction</p>
      </div>
      <div className="add-recurr-transaction-title-div">
        <p>How to add a Recurring Transaction in Best Budget</p>
      </div>
      <div className="add-recurr-transaction-section-container1">
        <div className="add-recurr-transaction-section-container1-description">
          <p>
            1. Click on the Transaction icon located on the navigation bar below
            to create a new transaction.
          </p>
        </div>
        <div className="add-recurr-transaction-section-container1-img1-div">
          <div className="add-recurr-transaction-section-container1-img1"></div>
        </div>
      </div>
      <div className="add-recurr-transaction-section-container2">
        <div className="add-recurr-transaction-section-container2-description">
          <p>
            2. By default, the expense transaction type will be selected. Select
            the required type of transaction.
          </p>
          <p> 3. Enter the necessary data for the transaction. </p>
          <p>
            4. Click on the 'Recurring' field to set up a scheduled transaction.
          </p>
        </div>
        <div className="add-recurr-transaction-section-container2-img1-div">
          <div className="add-recurr-transaction-section-container2-img1"></div>
        </div>
      </div>
      <div className="add-recurr-transaction-section-container3">
        <div className="add-recurr-transaction-section-container3-description">
          <p>5. Select Recurring Frequency.</p>
          <p>6. Click on 'Save' button to complete the Transaction.</p>
        </div>
        <div className="add-recurr-transaction-section-container3-img1-div">
          <div className="add-recurr-transaction-section-container3-img1"></div>
        </div>
      </div>
      <div className="add-recurr-transaction-title-div2">
        <p>How to open scheduled Transactions</p>
      </div>
      <div className="add-recurr-transaction-section-container4">
        <div className="add-recurr-transaction-section-container4-description">
          <p>
            1. Go to the 'All Accounts' Transactions page or open the
            transaction details of the desired account.
          </p>
          <p>2. Click on 'Scheduled' field to expand schedule transactions.</p>
        </div>
        <div className="add-recurr-transaction-section-container4-img1-div">
          <div className="add-recurr-transaction-section-container4-img1"></div>
        </div>
      </div>
      <div className="add-recurr-transaction-section-container5">
        <div className="add-recurr-transaction-section-container5-description">
          <p>3. Click on desired schedule transaction to edit or delete it.</p>
        </div>
        <div className="add-recurr-transaction-section-container5-img1-div">
          <div className="add-recurr-transaction-section-container5-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddRecurrTransaction;
