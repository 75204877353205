import React from "react";
import "./IncomeVsExpense.css";
const IncomeVsExpense = (props) => {
  
  return (
    <div className="income-vs-expense-main-container">
      <div className="income-vs-expense-breadcrumb">
        <p>Reports</p>
        <p> &gt; </p>
        <p>Income Vs Expense</p>
      </div>
      <div className="income-vs-expense-title-div">
        <p>Income Vs Expense report in Best Budget</p>
      </div>
      <div className="income-vs-expense-section-container1">
        <div className="income-vs-expense-section-container1-description">
          <p>
            1. Tap on the 'More' icon in the navigation bar and select the
            'Reports' icon.
          </p>
        </div>
        <div className="income-vs-expense-section-container1-img1-div">
          <div className="income-vs-expense-section-container1-img1"></div>
        </div>
      </div>
      <div className="income-vs-expense-section-container2">
        <div className="income-vs-expense-section-container2-description">
          <p>2. Click on the 'Income vs Expense' tab to access it.</p>
        </div>
        <div className="income-vs-expense-section-container2-img1-div">
          <div className="income-vs-expense-section-container2-img1"></div>
        </div>
      </div>
      <div className="income-vs-expense-section-container3">
        <div className="income-vs-expense-section-container3-description">
          <p>3.To open filters for reports, click on the 'filters' icon.</p>
        </div>
        <div className="income-vs-expense-section-container3-img1-div">
          <div className="income-vs-expense-section-container3-img1"></div>
        </div>
      </div>

      <div className="income-vs-expense-section-container4">
        <div className="income-vs-expense-section-container4-description">
          <p>
            4. By default, 'Accounts' is opened with 'Select All' filters. Click
            on 'Select None' to unselect all accounts or uncheck specific
            accounts as needed.
          </p>
          <p>
            {" "}
            Note: Categories are not available or inactive in reports' filters.
          </p>
        </div>
        <div className="income-vs-expense-section-container4-img1-div">
          <div className="income-vs-expense-section-container4-img1"></div>
        </div>
      </div>
      <div className="income-vs-expense-section-container5">
        <div className="income-vs-expense-section-container5-description">
          <p>
            5. By default, 'Year to Date (YTD)' is selected. You can select any
            other date filter if required.
          </p>
          <p>
            6. For Date range option, the default start date is given, and you
            can make changes as needed.{" "}
          </p>
          <p>
            7. Click on 'reset' button to reset all the filters. Click on
            'Apply' button to apply the filters.
          </p>
        </div>
        <div className="income-vs-expense-section-container5-img1-div">
          <div className="income-vs-expense-section-container5-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default IncomeVsExpense;
