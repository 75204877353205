import React from "react";
import "./FilterTransactions.css";
const FilterTransactions = (props) => {
  
  return (
    <div className="filter-transaction-main-container">
      <div className="filter-transaction-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Filter Transactions</p>
      </div>
      <div className="filter-transaction-title-div">
        <p>How to Filter Transactions in Best Budget</p>
      </div>
      <div className="filter-transaction-section-container1">
        <div className="filter-transaction-section-container1-description">
          <p>
            1. Go to the 'All Accounts' Transactions page or open the
            transaction details of the desired account.
          </p>
          <p>2. Click on the filters icon to open the filters menu.</p>
        </div>
        <div className="filter-transaction-section-container1-img1-div">
          <div className="filter-transaction-section-container1-img1"></div>
        </div>
      </div>
      <div className="filter-transaction-section-container2">
        <div className="filter-transaction-section-container2-description">
          <p>
            3. Accounts Filters: By default, 'Accounts' is opened with 'Select
            All' filters. Click on 'Select None' to unselect all accounts or
            uncheck specific accounts as needed.
          </p>

          <p>
            4. Other Filters: Click on 'Payees', 'Categories', 'Status', or
            'Transactions' to open their respective filters.
          </p>
        </div>
        <div className="filter-transaction-section-container2-img1-div">
          <div className="filter-transaction-section-container2-img1"></div>
        </div>
      </div>
      <div className="filter-transaction-section-container3">
        <div className="filter-transaction-section-container3-description">
          <p>
            5. Date Filters: Click on 'Date' to open Date filters. By default,
            'Year to Date (YTD)' is selected. You can select any other date
            filter if required.{" "}
          </p>
          <p>
            6. For Date range option, the default start date is given, and you
            can make changes as needed.
          </p>
          <p>
            7. Click on 'reset' button to reset all the filters. Click on
            'Apply' button to apply the filters.
          </p>
        </div>
        <div className="filter-transaction-section-container3-img1-div">
          <div className="filter-transaction-section-container3-img1"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default FilterTransactions;
