import React from "react";
import "./RearrangeAccount.css";
const RearrangeAccount = (props) => {
  
  return (
    <div className="rearrange-account-main-container">
      <div className="rearrange-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Re-arrange Account</p>
      </div>
      <div className="rearrange-account-title-div">
        <p>How to re-arrange an account in Best Budget</p>
      </div>
      <div className="rearrange-account-section-container1">
        <div className="rearrange-account-section-container1-description">
          <p>
            1. In the Accounts tab, locate and click on the rearrange icon
            positioned at the top right corner of the screen.
          </p>
        </div>
        <div className="rearrange-account-section-container1-img1-div">
          <div className="rearrange-account-section-container1-img1"></div>
        </div>
      </div>
      <div className="rearrange-account-section-container2">
        <div className="rearrange-account-section-container2-description">
          <p>
            2. Hold and drag the desired account field to move it to the desired
            position.
          </p>
          <p>
            {" "}
            3. You can drag the account field to the top or bottom of the list
            to rearrange the order of your accounts as you wish and click on
            'Done' button.
          </p>
        </div>
        <div className="rearrange-account-section-container2-img1-div">
          <div className="rearrange-account-section-container2-img1"></div>
        </div>
      </div>
      

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default RearrangeAccount;
