import React, { useState, useEffect, useRef } from 'react'
import './EmailVerificationPage.css'
import Footer from './Footer';
import axios from 'axios';
import environment from "../environment";
import homeScreen from "../Assets/EmailVerification/homeScreen.png";
import emailVerifiedIcon from "../Assets/EmailVerification/emailVerifiedIcon.png";
import emailNotVerifiedIcon from "../Assets/EmailVerification/emailNotVerifiedIcon.png";

const EmailVerificationPage = () => {
    const [enableSuccessIcon, setEnableSuccessIcon] = useState(false);
    const [toggleImage, setToggleImage] = useState(false);
    const [contentDescriptionStatusCode, setContentDescriptionStatusCode] = useState(null);

    const checkWhetherUserVerified = async () => {
        const query = new URLSearchParams(window.location.search);
        const userId = query.get("userId");
        const token = query.get("token");
        let getIsVerifiedUrl = environment.baseUrl + `api/user/verify/${userId}/${token}`;
        try {
            let res = await axios.get(getIsVerifiedUrl);
            let resData = res.data;
            if (resData.status === 200) {
                setToggleImage(true);
                setEnableSuccessIcon(true);
                setContentDescriptionStatusCode(resData.status);
            }
            // User already verified
             else if (resData.status === 400) {
                setToggleImage(true);
                setEnableSuccessIcon(true);
                setContentDescriptionStatusCode(resData.status);
            } 
            // Email Verification link is expired
            else if (resData.status === 403) {
                setToggleImage(true);
                setEnableSuccessIcon(false);
                setContentDescriptionStatusCode(resData.status);
            } 
            //Email Verification link is invalid
            else if (resData.status === 401) {
                setToggleImage(true);
                setEnableSuccessIcon(false);
                setContentDescriptionStatusCode(resData.status);
            } 
            //there was some internal server error
            else if (resData.status === 500) {
                setToggleImage(true);
                setEnableSuccessIcon(false);
                setContentDescriptionStatusCode(resData.status);
            }
        } catch (error) {
            setToggleImage(true);
            setEnableSuccessIcon(false);
            setContentDescriptionStatusCode(500);
        }
    };
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const userId = query.get("userId");
        const timer = setTimeout(() => {
            if (userId) {
                console.log("Inside the if loop")
                checkWhetherUserVerified();
            }
        }, 1000);
        return () => clearTimeout(timer);


    }, []);
    return (
        <React.Fragment>
            <div className='email-verify-main-container'>
                <div className='section1-container'>
                    <div className='section1-content'>
                        <h1 className='section1-content-header'>Welcome to Best Budget</h1>
                        <div className='section1-content-description'>
                            {
                                contentDescriptionStatusCode === 200 ?
                                <p>All set! Your email is verified. Login to the app to get started.</p> :
                                    contentDescriptionStatusCode === 400 ?
                                    <p><span>Email verified! </span> Please proceed to log in</p> :
                                        contentDescriptionStatusCode === 401 ? 
                                        <p>Looks like that <span>verification link is invalid.</span> Enter a valid link or sign up again!</p> :
                                            contentDescriptionStatusCode === 403 ? 
                                            <p><span>Verification link expired.</span>, please sign up again!</p> :
                                                contentDescriptionStatusCode === 500 ? 
                                                <p>Sorry for the inconvenience, there was some internal server error. Please signup again.</p> :
                                                    <p>Loading...</p>
                            }
                        </div>
                        {
                            toggleImage ? <img src={enableSuccessIcon ? emailVerifiedIcon : emailNotVerifiedIcon} alt='' /> : ""

                        }
                    </div>
                    <div className='section1-image-container'>
                        <img src={homeScreen} alt='' />
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment >

    )
}

export default EmailVerificationPage