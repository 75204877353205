import React from 'react';
import "./ChangeBalance.css";
const ChangeBalance = (props) => {
 
  return (
    <div className="change-balance-main-container">
      <div className="change-balance-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Change Balance</p>
      </div>
      <div className="change-balance-title-div">
        <p>How to change my Account Balance in Best Budget</p>
      </div>
      <div className="change-balance-section-container1">
        <div className="change-balance-section-container1-description">
          <p>
          1. Click on the account field for the account you wish to edit to access its details.
          </p>
        </div>
        <div className="change-balance-section-container1-img1-div">
          <div className="change-balance-section-container1-img1"></div>
        </div>
      </div>
      <div className="change-balance-section-container2">
        <div className="change-balance-section-container2-description">
          <p>
            2. On the account details page, tap on the "Menu" icon located in
            the top right corner.
          </p>
          <p>3. From the menu options, select "Edit Account".</p>
        </div>
        <div className="change-balance-section-container2-img1-div">
          <div className="change-balance-section-container2-img1"></div>
        </div>
      </div>
      <div className="change-balance-section-container3">
        <div className="change-balance-section-container3-description">
          <p>
          4. Adjust your current Balance in the 'Edit Account' screen.
          </p>
        </div>
        <div className="change-balance-section-container3-img1-div">
          <div className="change-balance-section-container3-img1"></div>
        </div>
      </div>
      
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  )
}

export default ChangeBalance