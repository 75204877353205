import React from "react";
import "./CookiesPolicy.css";
import Footer from "../Footer";
const CookiesPolicy = (props) => {
  
  return (
    <React.Fragment>
      <div className="cookies-policy-main-container">
        <div className="cookies-policy-actual-content-container">
          <div className="cookies-policy-first-section">
            <div className="cookies-policy-heading1">
              <p>Cookies Policy</p>
            </div>
            <div className="cookies-policy-last-updated-div">
              <p>Last updated: 1st April 2024</p>
            </div>
            <div className="cookies-policy-description1">
              <p>
                Our website utilizes cookies to analyse site traffic, customize
                content, and enhance your browsing experience. Learn more about
                how we utilize cookies and explore options for managing them
                below.
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section1">
            <h3 className="cookies-policy-sub-section1-title">Cookie policy</h3>
            <div className="cookies-policy-sub-section1-description">
              <p>
                Best Budget, operated by CREXIN Technologies Private Limited
                ("CREXIN", "we", "us", and "our"), utilizes cookies and similar
                technologies as part of our business operations. This Cookie
                Policy outlines how we employ cookies across our websites,
                including those accessible via{" "}
                <a href="https://www.bestbudget.in">
                  https://www.bestbudget.in
                </a>{" "}
                and{" "}
                <a href="https://live.bestbudget.in">
                  https://live.bestbudget.in
                </a>
                , as well as any other website or mobile application under our
                ownership, operation, or control (collectively referred to as
                "Websites" or "Service").
              </p>
              <p>
                This policy elucidates the nature of these technologies and
                their purpose, along with your entitlement to manage our
                utilization of them. It's important to note that this Cookie
                Policy is an integral component of our Terms of Service and is
                inherently integrated therein.
              </p>
              <p>
                At times, we may employ cookies and tracking technologies
                outlined in this Cookie Policy to gather personal information or
                information that becomes personal when combined with other data.
                For further details on how we handle your personal information,
                kindly refer to our <a href="https://bestbudget.in/privacy-policy" target="_blank" rel="noreferrer"  style={{color:"blue"}}>Privacy Policy</a>.
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section2">
            <h3 className="cookies-policy-sub-section2-title">
              What is a cookie?
            </h3>
            <div className="cookies-policy-sub-section2-description">
              <p>
                "Cookies" refer to small text files that can be stored on your
                computer or mobile device. These files are used to recognize
                your web browser and track the activities of your device while
                you interact with the Best Budget service and other websites.
              </p>
              <p>
                Cookies are extensively utilized by online service providers to
                ensure the functionality of their websites or services and
                enhance efficiency. Additionally, they are employed to gather
                reporting information for analysis and optimization purposes.
              </p>
              <p>
                Cookies established by the website owner or service provider,
                such as Best Budget, are referred to as "first-party cookies."
                On the other hand, cookies established by entities other than
                the website owner are known as "third-party cookies."
                Third-party cookies facilitate the provision of third-party
                features or functionality on the website or service you are
                using, including interactive content and usage analytics.
              </p>
              <p>
                The third parties responsible for setting these third-party
                cookies have the capability to identify your computer both when
                it accesses the website or service in question and when it
                visits specific other websites or services.{" "}
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section3">
            <h3 className="cookies-policy-sub-section3-title">
              Best Budget utilizes cookies for various purposes:
            </h3>
            <div className="cookies-policy-sub-section3-description">
              <p>
                We utilize both first-party and third-party cookies for various
                purposes. Some cookies are essential for technical reasons to
                ensure the proper operation of our Websites and Services, and we
                classify these as "necessary" cookies. For instance, when you
                log into your account using the Service, we employ a cookie to
                maintain your login status.
              </p>
              <p>
                Additionally, other first-party and third-party cookies allow us
                and our partners to analyse the performance of our Websites and
                Services. These cookies, known as "performance" or
                "functionality" cookies, enable us to personalize your
                experience and enhance the functionality of the Services we
                offer. For instance, they help tailor your interaction with our
                websites and improve the overall user experience.
              </p>
              <p>
                Lastly, third parties may set cookies through our Websites and
                Services for analytics purposes. Further details about this are
                provided below.
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section4">
            <h3 className="cookies-policy-sub-section4-title">
              Cookies served through our websites.
            </h3>
            <div className="cookies-policy-sub-section4-description">
              <div className="cookies-policy-sub-section4-description-column1">
                <p>Best Budget </p>
                <p>
                  These cookies are strictly necessary to provide you with
                  services available through our websites and to use some of its
                  features, such as access to secure areas.{" "}
                </p>
              </div>
              <div className="cookies-policy-sub-section4-description-column2">
                <p>_bestbudget_session_id </p>
                <p> user_id</p>
                <p>current_user</p>
                <p>current_user</p>
                <p>forecaster</p>
                <p>checklist</p>
                <p>counts</p>
                <p>is_demo</p>
                <p>onboarding_state</p>
                <p>week_start_day</p>
                <p>forecast_update_expected_after </p>
              </div>
            </div>
          </div>
          <div className="cookies-policy-description2">
            <p>
              Below is a detailed description of the specific first-party and
              third-party cookies served through our Websites, along with the
              purposes they serve:{" "}
            </p>
          </div>
          <div className="cookies-policy-sub-section5">
            <h3 className="cookies-policy-sub-section5-title">
              Necessary Website Cookies
            </h3>
            <div className="cookies-policy-sub-section5-description">
              <p>
                These cookies are essential and strictly necessary to enable you
                to access services available through our websites and utilize
                certain features, such as accessing secure areas. They are
                indispensable for delivering the websites, and as such, you
                cannot decline them without affecting the functionality of our
                websites. You have the option to block or delete these cookies
                by adjusting your browser settings.
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section6">
            <h3 className="cookies-policy-sub-section6-title">
              Analytics and Customization Cookies
            </h3>
            <div className="cookies-policy-sub-section6-description">
              <p>
                These cookies gather information, either in aggregate form to
                aid in understanding how our websites are utilized or to assess
                the effectiveness of our marketing campaigns. Alternatively,
                they may be utilized to customize our websites and applications
                for your benefit, enhancing your overall experience.
              </p>
              <div className="cookies-policy-sub-section6-description-table">
                <div className="cookies-policy-sub-section6-description-table-column1">
                  <p>Google</p>
                  <p>
                    Google Analytics gathers information allowing us to
                    understand interactions with our websites and ultimately
                    refine that experience to better serve you.{" "}
                  </p>
                </div>
                <div className="cookies-policy-sub-section6-description-table-column2">
                  <p>_ga</p>
                  <p>_ga</p>
                  <p>_gid</p>
                  <p>_utma</p>
                  <p>_utmb</p>
                  <p>_utmc</p>
                  <p>_utmz</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cookies-policy-sub-section7">
            <h3 className="cookies-policy-sub-section7-title">
              Other tracking technologies
            </h3>
            <div className="cookies-policy-sub-section7-description">
              <p>
                In addition to cookies, we and our third-party partners may
                utilize other similar technologies, such as pixels (or "clear
                gifs"), and other tracking technologies. These are tiny graphic
                files containing a unique identifier that allows us to recognize
                when an email we've sent has been opened. These technologies
                enable us to:
                <ol type="1">
                  <li>
                    Monitor traffic patterns from one page to another within our
                    websites.
                  </li>
                  <li>Deliver or communicate with cookies.</li>
                  <li>
                    Determine if you've arrived at our websites from an online
                    advertisement displayed on a third-party website.
                  </li>
                  <li>Enhance site performance.</li>
                  <li>Measure the success of marketing campaigns.</li>
                </ol>
              </p>
              <p>
                While you may not have the ability to explicitly reject or
                disable these tracking technologies, in many cases, they rely on
                cookies to function properly. Consequently, declining cookies
                may impact the functionality of these technologies.{" "}
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section8">
            <h3 className="cookies-policy-sub-section8-title">
              Third party services
            </h3>
            <div className="cookies-policy-sub-section8-description">
              <p>
                We've collaborated with one or more third-party service
                providers to track and analyse both individualized usage and
                volume statistical information derived from interactions with
                our websites. Additionally, we partner with one or more third
                parties, such as advertisers and ad networks, to oversee and
                deliver advertising on other sites.
              </p>
              <p>
                These third-party service providers may utilize cookies or web
                beacons to gather information about your visits to our websites
                and/or other sites. This data is utilized to gauge and track the
                effectiveness of advertisements and our online marketing
                endeavours. For instance, they may collect data on how
                frequently you click on one of our ads.
              </p>
              <p>
                It's important to note that the information gathered by these
                third parties does not include personal information that can
                specifically identify you, such as your name or email address.
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section9">
            <h3 className="cookies-policy-sub-section9-title">Log files</h3>
            <div className="cookies-policy-sub-section9-description">
              <p>
                We gather non-personal information via our Internet log files,
                which capture data like browser types, domain names, and other
                anonymous statistical data related to the utilization of the
                Best Budget Websites and Services. This data may be utilized to
                analyse trends, administer, and monitor service usage, and
                collect general demographic information.
              </p>
              <p>
                At times, we may correlate this information with personal data
                for various purposes, including personalizing your experience on
                the Best Budget Services.
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section10">
            <h3 className="cookies-policy-sub-section10-title">
              How often will this Cookie Policy be updated?
            </h3>
            <div className="cookies-policy-sub-section10-description">
              <p>
                The date at the top of this Cookie Policy indicates when it was
                last updated. We reserve the right to update this Cookie Policy
                periodically to reflect changes to the cookies we utilize or for
                other operational, legal, or regulatory reasons. We encourage
                you to revisit this Cookie Policy regularly to stay informed
                about our use of cookies and related technologies.
              </p>
              <p>
                The date at the top of this Cookie Policy denotes when it was
                last updated.
              </p>
            </div>
          </div>
          <div className="cookies-policy-sub-section11">
            <h3 className="cookies-policy-sub-section11-title">
              Where can I get further information?
            </h3>
            <div className="cookies-policy-sub-section11-description">
              <p>
                If you have any questions about our use of cookies or other
                technologies, please email us at <a href="mailto:support@bestbudget.in">support@bestbudget.in</a>
              </p>
            </div>
          </div>
          <div className="cookies-policy-go-to-top">
            <div
              className="go-to-page-top"
              onClick={() => {
                props.scrollToTop();
              }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CookiesPolicy;
