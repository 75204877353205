import React from "react";
import { useNavigate } from "react-router-dom";
import "./Blog.css";
import Footer from "./Footer";
import financialPlanning from "../Assets/Blog/financialPlanning.webp";
import budgetingImage from "../Assets/Blog/budgeting.webp";
import InvestingImage from "../Assets/Blog/investing.webp";
import savingsImage from "../Assets/Blog/savings.webp";
import readMoreIcon from "../Assets/Blog/readMoreIcon.svg";
import SEO from "./SEO";
const Blog = (props) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
    <SEO
        title='BestBudget - Blog'
        description='Financial planning is an essential aspect of achieving long-term financial success and stability. Whether you are just starting your career'
      />
      <div className="blog-main-container">
        <div className="blog-section1-container">
          <div className="blog-section1-content">
            <p className="blog-h2">Featured</p>
            <h3
              className="blog-title"
              onClick={() => {
                navigate(
                  "/blog/need-to-achieve-good-outcome-from-financial-planning"
                );
              }}
            >
              Need to achieve good outcome from Financial Planning ?
            </h3>
            <div className="blog-section1-content-description">
              <div className="blog-section1-content-desc-text">
                <p>
                  Financial planning is an essential aspect of achieving
                  long-term financial success and stability. Whether you're just
                  starting your career, planning for a family, or looking toward
                  retirement, effective financial planning can help you reach
                  your goals.
                </p>
                <div
                  className="read-more-text"
                  onClick={() => {
                    navigate(
                      "/blog/need-to-achieve-good-outcome-from-financial-planning"
                    );
                  }}
                >
                  <p>Read more</p>
                  <img src={readMoreIcon} alt="read more" />
                </div>
              </div>

              <div className="blog-section1-content-image">
                <img
                  src={financialPlanning}
                  alt="financial planning"
                  onClick={() => {
                    navigate(
                      "/blog/need-to-achieve-good-outcome-from-financial-planning"
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="blog-section1-grid-container">
            <div
              className="blog-grid1 grid"
              onClick={() => {
                navigate(
                  "/blog/how-budgeting-drives-financial-success-and-management"
                );
                props.scrollToTop();
              }}
            >
              <img src={budgetingImage} alt="" />
              <h3 className="grid-heading">
                How Budgeting Drives Financial Success and Management
              </h3>
              <p>
                Budgeting is the cornerstone of effective financial management
                and a crucial driver of financial success. It involves creating
                a plan to manage your money, tracking your income and expenses,
                and making informed financial decisions.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid2 grid"
              onClick={() => {
                navigate(
                  "/blog/top-tips-for-tracking-all-your-investments-in-one-place"
                );
                props.scrollToTop();
              }}
            >
              <img src={InvestingImage} alt="" />
              <h3 className="grid-heading">
                Top Tips for Tracking All Your Investments in One Place
              </h3>
              <p>
                Investing can be an exciting journey towards financial freedom.
                But with a diverse portfolio spread across different accounts,
                tracking it all can feel overwhelming.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid3 grid"
              onClick={() => {
                navigate(
                  "/blog/effective-tips-for-saving-money-quickly-and-easily"
                );
                props.scrollToTop();
              }}
            >
              <img src={savingsImage} alt="" />
              <h3 className="grid-heading">
                Effective Tips for Saving Money Quickly and Easily
              </h3>
              <p>
                Living paycheck to paycheck? Feeling the pinch of inflation?
                Don't worry, you're not alone. But fret not, fellow budget
                warriors! Here's a treasure trove of practical tips to help you
                save money quickly and easily, putting you back in control of
                your finances.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid1 grid"
              onClick={() => {
                navigate(
                  "/blog/how-budgeting-drives-financial-success-and-management"
                );
                props.scrollToTop();
              }}
            >
              <img src={budgetingImage} alt="" />
              <h3 className="grid-heading">
                How Budgeting Drives Financial Success and Management
              </h3>
              <p>
                Budgeting is the cornerstone of effective financial management
                and a crucial driver of financial success. It involves creating
                a plan to manage your money, tracking your income and expenses,
                and making informed financial decisions.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid2 grid"
              onClick={() => {
                navigate(
                  "/blog/top-tips-for-tracking-all-your-investments-in-one-place"
                );
                props.scrollToTop();
              }}
            >
              <img src={InvestingImage} alt="" />
              <h3 className="grid-heading">
                Top Tips for Tracking All Your Investments in One Place
              </h3>
              <p>
                Investing can be an exciting journey towards financial freedom.
                But with a diverse portfolio spread across different accounts,
                tracking it all can feel overwhelming.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid3 grid"
              onClick={() => {
                navigate(
                  "/blog/effective-tips-for-saving-money-quickly-and-easily"
                );
                props.scrollToTop();
              }}
            >
              <img src={savingsImage} alt="" />
              <h3 className="grid-heading">
                Effective Tips for Saving Money Quickly and Easily
              </h3>
              <p>
                Living paycheck to paycheck? Feeling the pinch of inflation?
                Don't worry, you're not alone. But fret not, fellow budget
                warriors! Here's a treasure trove of practical tips to help you
                save money quickly and easily, putting you back in control of
                your finances.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid1 grid"
              onClick={() => {
                navigate(
                  "/blog/how-budgeting-drives-financial-success-and-management"
                );
                props.scrollToTop();
              }}
            >
              <img src={budgetingImage} alt="" />
              <h3 className="grid-heading">
                How Budgeting Drives Financial Success and Management
              </h3>
              <p>
                Budgeting is the cornerstone of effective financial management
                and a crucial driver of financial success. It involves creating
                a plan to manage your money, tracking your income and expenses,
                and making informed financial decisions.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid2 grid"
              onClick={() => {
                navigate(
                  "/blog/top-tips-for-tracking-all-your-investments-in-one-place"
                );
                props.scrollToTop();
              }}
            >
              <img src={InvestingImage} alt="" />
              <h3 className="grid-heading">
                Top Tips for Tracking All Your Investments in One Place
              </h3>
              <p>
                Investing can be an exciting journey towards financial freedom.
                But with a diverse portfolio spread across different accounts,
                tracking it all can feel overwhelming.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid3 grid"
              onClick={() => {
                navigate(
                  "/blog/effective-tips-for-saving-money-quickly-and-easily"
                );
                props.scrollToTop();
              }}
            >
              <img src={savingsImage} alt="" />
              <h3 className="grid-heading">
                Effective Tips for Saving Money Quickly and Easily
              </h3>
              <p>
                Living paycheck to paycheck? Feeling the pinch of inflation?
                Don't worry, you're not alone. But fret not, fellow budget
                warriors! Here's a treasure trove of practical tips to help you
                save money quickly and easily, putting you back in control of
                your finances.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid1 grid"
              onClick={() => {
                navigate(
                  "/blog/how-budgeting-drives-financial-success-and-management"
                );
                props.scrollToTop();
              }}
            >
              <img src={budgetingImage} alt="" />
              <h3 className="grid-heading">
                How Budgeting Drives Financial Success and Management
              </h3>
              <p>
                Budgeting is the cornerstone of effective financial management
                and a crucial driver of financial success. It involves creating
                a plan to manage your money, tracking your income and expenses,
                and making informed financial decisions.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid2 grid"
              onClick={() => {
                navigate(
                  "/blog/top-tips-for-tracking-all-your-investments-in-one-place"
                );
                props.scrollToTop();
              }}
            >
              <img src={InvestingImage} alt="" />
              <h3 className="grid-heading">
                Top Tips for Tracking All Your Investments in One Place
              </h3>
              <p>
                Investing can be an exciting journey towards financial freedom.
                But with a diverse portfolio spread across different accounts,
                tracking it all can feel overwhelming.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
            <div
              className="blog-grid3 grid"
              onClick={() => {
                navigate(
                  "/blog/effective-tips-for-saving-money-quickly-and-easily"
                );
                props.scrollToTop();
              }}
            >
              <img src={savingsImage} alt="" />
              <h3 className="grid-heading">
                Effective Tips for Saving Money Quickly and Easily
              </h3>
              <p>
                Living paycheck to paycheck? Feeling the pinch of inflation?
                Don't worry, you're not alone. But fret not, fellow budget
                warriors! Here's a treasure trove of practical tips to help you
                save money quickly and easily, putting you back in control of
                your finances.
              </p>
              <div
                className="read-more-text-grid"
                // onClick={() => {
                //     navigate(
                //         "/blog/need-to-achieve-good-outcome-from-financial-planning"
                //     );
                // }}
              >
                <p>Read more</p>
                <img
                  className="read-more-text-grid-img"
                  src={readMoreIcon}
                  alt="read more"
                />
              </div>
            </div>
          </div>
          <div className="blog-section2-content-bg">
          <div className="blog-section2-content">
            <p className="blog-section2-content-desc-text">Unlock the potential of your Money. Start Best Budget Today..</p>
            <div className="blog-section2-content-start-free-trial-div">
                 <button className="blog-section2-content-start-free-trial">
                      Start Free Trial
                 </button> 
            </div>
          </div>
          </div>
          <div className="blog-go-to-top">
              <div
                className="go-to-page-top"
                onClick={() => {
                  props.scrollToTop();
                }}
              ></div>
            </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Blog;
