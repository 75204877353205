import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import bestBudgetIcon from "../Assets/bestBudgetIcon.svg";
import twitterXIcon from "../Assets/LandingWebsite/twiiterXIcon.png";
import youtubeIcon from "../Assets/LandingWebsite/youtubeIcon.png";
import facebookIcon from "../Assets/LandingWebsite/facebookIcon.png";
import instagramIcon from "../Assets/LandingWebsite/instagramIcon.png";
import copyRightIcon from "../Assets/LandingWebsite/copyRightIcon.svg";
import linkedInIcon from "../Assets/LandingWebsite/linkedInIcon.png";
import { MenuContext } from '../MenuContext';
import "./Footer.css";
const Footer = (props) => {
  const { isMenuOpen, toggleMenu } = useContext(MenuContext);
  
  const navigate = useNavigate();
  
  const scrollToMiddle = () => {
    window.scrollTo({
      top: 1900,
      behavior: "smooth",
    });
  };
  const handleSocialClick = (url) => {
    window.open(url, '_blank');
  };
  const handleCloseOpenNavbar = () => {
    if (isMenuOpen === true) {
      toggleMenu(false)
    } else
      return
  }
 
  return (
    <div className="website-footer-section">
      <div className="section section-container6">
        <div className="footer-routes-box">
          <div className="application-div">
            <div className="application-title">Application</div>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/");
                handleCloseOpenNavbar();
              }}
            >
              Home
            </p>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/why-best-budget");
                handleCloseOpenNavbar();
              }}
            >
              Why Best Budget
            </p>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/pricing");
                handleCloseOpenNavbar();
              }}
            >
              Pricing
            </p>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/learn");
                handleCloseOpenNavbar();
              }}
            >
              Learn
            </p>
          </div>
          <div className="legal-div">
            <div className="legal-title">Legal</div>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/privacy-policy");
                handleCloseOpenNavbar();
              }}
            >
              Privacy Policy
            </p>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/service-terms");
                handleCloseOpenNavbar();
              }}
            >
              Service Terms
            </p>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/security");
                handleCloseOpenNavbar();
              }}
            >
              Security
            </p>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/cookies-policy");
                handleCloseOpenNavbar();
              }}
            >
              Cookies Policy
            </p>
          </div>
          <div className="about-div">
            <div className="about-title">About</div>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/about-us");
                handleCloseOpenNavbar();
              }}
            >
              About Us
            </p>
            <p
              onClick={() => {
                props.scrollToTop();
                navigate("/blog");
                handleCloseOpenNavbar();
              }}
            >
              Blog
            </p>
            <p onClick={() => {
              scrollToMiddle();
              navigate("/about-us");
              handleCloseOpenNavbar();
            }}>Support</p>
          </div>
        </div>
        <div className="footer-title-box">
          {window.innerWidth > 430 ? <div
            className="company-logo"
            onClick={() => {
              props.scrollToTop();
              navigate("/");
              handleCloseOpenNavbar();
            }}
          >
            <img alt="" src={bestBudgetIcon} className="footer-icon" />
            <p className="footer-navbar-brand navbar-brand">BEST BUDGET</p>
          </div> : <div
            className="navbar-brand-icon"
            onClick={() => {
              props.scrollToTop();
              navigate("/");
              handleCloseOpenNavbar();
            }}
          ></div>}
          <div className="follow-us-on">
            <p>Follow us on</p>
          </div>
          <div className="social-media-icon-tray">
            <img  loading="lazy" alt="our twiiter handle" src={twitterXIcon} className="twitter-icon" onClick={() => { handleSocialClick("https://twitter.com/BestBudget_") }} />
            <img  loading="lazy" alt="our insta page" src={instagramIcon} className="instagram-icon" onClick={() => { handleSocialClick("https://www.instagram.com/bestbudget_insta/?hl=en") }} />
            <img  loading="lazy" alt="our facebook page" src={facebookIcon} className="facebook-icon" onClick={() => { handleSocialClick("https://www.facebook.com/profile.php?id=61557155228371") }} />
            <img  loading="lazy" alt="our youtube channel for learning" src={youtubeIcon} className="youtube-icon" onClick={() => { handleSocialClick("https://www.youtube.com/channel/UClxaV2Vn09kLB9aU6yxh5HA") }} />
            <img  loading="lazy" alt="our linkedin page" src={linkedInIcon} className="linkedin-icon" onClick={() => { handleSocialClick("https://www.linkedin.com/showcase/100918723/admin/feed/posts/") }} />
          </div>
          <div className="copy-right-panel">
            <p>
              Copyright
              <img alt="" src={copyRightIcon} />
              2022 CREXIN Technologies. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
