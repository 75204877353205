import React from "react";
import "./StartBudgeting.css";

const StartBudgeting = (props) => {
  
  return (
    <div className="start-budgeting-main-container">
      <div className="start-budgeting-breadcrumb">
        <p>Get Started</p>
        <p> &gt; </p>
        <p>Start Budgeting</p>
      </div>
      <div className="start-budgeting-title-div">
        <p>Getting started with Best Budget</p>
      </div>
      <div className="start-budgeting-description">
        <p>
          Take charge of your finances effortlessly with our Budget App,
          designed to simplify budgeting, track expenses, and set financial
          goals. Start your journey towards financial success today and achieve
          your aspirations with ease with the following steps.
        </p>
        <ol type="1">
          <li>Add Accounts</li>
          <li>Add Budget Categories</li>
          <li>Allocate Money to Categories</li>
          <li>Enter Transactions</li>
          <li>Analyse Reports</li>
        </ol>
      </div>
      <div className="start-budgeting-section-comtainer1">
        <div className="start-budgeting-section-container1-title">
          <p>1. Add Accounts</p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <p>
            List out all of your accounts like Bank Accounts, Credit
            Cards,Deposits and Mortgages and add one by one to Best Budget to
            reflect your Networth.
          </p>
        </div>
        <div className="start-budgeting-section-container1-img1-div">
          <div className="start-budgeting-section-container1-img1"></div>
        </div>
        <div className="start-budgeting-section-container1-img2-div">
          <div className="start-budgeting-section-container1-img2"></div>
        </div>
      </div>
      <div className="start-budgeting-section-comtainer2">
        <div className="start-budgeting-section-container2-title">
          <p>2. Add Budget Categories</p>
        </div>
        <div className="start-budgeting-section-container2-description">
          <p>
            Review your expense categories. To make it easy we added few default
            categories, which you can keep, modify, delete or even add your own
            categories.
          </p>
        </div>
        <div className="start-budgeting-section-container2-img1-div">
          <div className="start-budgeting-section-container2-img1"></div>
        </div>
        <div className="start-budgeting-section-container2-img2-div">
          <div className="start-budgeting-section-container2-img2"></div>
        </div>
      </div>
      <div className="start-budgeting-section-comtainer3">
        <div className="start-budgeting-section-container3-title">
          <p>3. Allocate Money to Categories</p>
        </div>
        <div className="start-budgeting-section-container3-description">
          <p>
            Make allocations to your expense categories based on your past
            expenditure. Don’t worry you can always adjust your allocation.
          </p>
        </div>
        <div className="start-budgeting-section-container3-img1-div">
          <div className="start-budgeting-section-container3-img1"></div>
        </div>
        <div className="start-budgeting-section-container3-img2-div">
          <div className="start-budgeting-section-container3-img2"></div>
        </div>
      </div>
      <div className="start-budgeting-section-comtainer4">
        <div className="start-budgeting-section-container4-title">
          <p>4. Enter Transactions</p>
        </div>
        <div className="start-budgeting-section-container4-description">
          <p>
            On a daily basis record all your income, expense or transfer
            Transactions to keep the Best Budget up to date on the balances.
          </p>
        </div>
        <div className="start-budgeting-section-container4-img1-div">
          <div className="start-budgeting-section-container4-img1"></div>
        </div>
        <div className="start-budgeting-section-container4-img2-div">
          <div className="start-budgeting-section-container4-img2"></div>
        </div>
      </div>
      <div className="start-budgeting-section-comtainer5">
        <div className="start-budgeting-section-container5-title">
          <p>5. Analyse Reports</p>
        </div>
        <div className="start-budgeting-section-container5-description">
          <p>
            Analyze your budget reports to gain insights of your financial
            activities, identify trends, and make informed decisions to optimize
            your budget and achieve your financial goals.
          </p>
        </div>
        <div className="start-budgeting-section-container5-img1-div">
          <div className="start-budgeting-section-container5-img1"></div>
        </div>
        <div className="start-budgeting-section-container5-img2-div">
          <div className="start-budgeting-section-container5-img2"></div>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default StartBudgeting;
