import React from "react";
import { useNavigate } from "react-router-dom";
import "./Learn.css";
import CategoriesIcon from "../Assets/Learn/CategoriesIcon.svg";
import AccountsIcon from "../Assets/Learn/AccountsIcon.svg";
import TransactionsIcon from "../Assets/Learn/TransactionsIcon.svg";
import DashboardIcon from "../Assets/Learn/DashboardIcon.svg";
import ReportsIcon from "../Assets/Learn/ReportsIcon.svg";
import PayeesIcon from "../Assets/Learn/PayeesIcon.svg";
import SettingsIcon from "../Assets/Learn/SettingsIcon.svg";
import ProfileIcon from "../Assets/Learn/ProfileIcon.svg";
import OthersIcon from "../Assets/Learn/OthersIcon.svg";
import boyRunning from "../Assets/Learn/boyRunning.webp";
import Footer from "./Footer";
import SEO from "./SEO";
const Learn = (props) => {

  const navigate = useNavigate();

  const handleNavigation = ({ module, path }) => {
    navigate("/learn/learning-center", {
      state: { module: module, path: path },
    });
  };
  return (
    <React.Fragment>
    <SEO
        title='BestBudget - Learn'
        description='we are excited to assist you in getting started and running smoothly with this innovative method for your money management!'
      />
      <div className="learn-main-container">
        <div className="learn-section-container1">
          <div className="learn-section-container1-title">
            <p>Learn to Use Best Budget</p>
          </div>
          <div className="ln-section-container1-content-div">
            <div className="ln-section-container1-description">
              <div className="ln-section-container1-description-title">
                <p>Get Started with Best Budget</p>
              </div>
              <div className="ln-section-container1-description-text">
                <p>
                  Congratulations! Being ready and willing to dive into your
                  finances with eyes wide open is at least half the battle.
                </p>
                <p>
                Now, we're excited to assist you in getting started and running smoothly with this innovative method for your money management!
                </p>
              </div>
            </div>
            <div className="ln-section-container1-img-div">
              <div className="ln-section-container1-img">
                <img src={boyRunning} alt="A boy eagerly picks up a frisbee, symbolizing his readiness to explore the features of the Best Budget personal finance application." />
              </div>
              <div
                className="ln-section-container1-learn-more-button"
                onClick={() => {
                  handleNavigation({
                    module: "Get Started",
                    path: "/learn/learning-center/get-started-start-budgeting",
                  });
                }}
              >
                <p>Learn More</p>
                <div className="ln-section-container1-learn-more-button-img"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="learn-section-container2">
          <div className="ln-section-container-grid">
            <div className="ln-section-container2-grid1">
              <div className="ln-section-container2-grid1-img">
                <img src={AccountsIcon} alt="" />
              </div>
              <div className="ln-section-container2-grid1-title">
                <p>Accounts</p>
              </div>
              <div className="ln-section-container2-grid1-description">
                <p>
                  To begin managing your finances effectively, it's essential to
                  create accounts. Start by adding your various financial
                  assets.
                </p>
              </div>
              <div className="ln-section-container2-grid1-description-learn-more">
                <p
                  onClick={() => {
                    handleNavigation({
                      module: "Accounts",
                      path: "/learn/learning-center/accounts-add-account",
                    });
                    props.scrollToTop();
                  }}
                >
                  Learn more
                </p>
              </div>
            </div>
            <div className="ln-section-container2-grid2">
              <div className="ln-section-container2-grid2-img">
                <img src={CategoriesIcon} alt="" />
              </div>
              <div className="ln-section-container2-grid2-title">
                <p>Budget</p>
              </div>
              <div className="ln-section-container2-grid2-description">
                <p>
                  Start budgeting effectively by creating categories, making it
                  easier to track your spending habits.
                </p>
              </div>
              <div className="ln-section-container2-grid2-description-learn-more">
                <p
                  onClick={() => {
                    handleNavigation({
                      module: "Budget",
                      path: "/learn/learning-center/budget-add-category-group",
                    });
                    props.scrollToTop();
                  }}
                >
                  Learn more
                </p>
              </div>
            </div>
            <div className="ln-section-container2-grid3">
              <div className="ln-section-container2-grid3-img">
                <img src={TransactionsIcon} alt=""/>
              </div>
              <div className="ln-section-container2-grid3-title">
                <p>Transactions</p>
              </div>
              <div className="ln-section-container2-grid3-description">
                <p>
                  Record all your transactions accurately to make informed
                  decisions about your money, and organize your financial
                  activity.
                </p>
              </div>
              <div className="ln-section-container2-grid3-description-learn-more">
                <p
                  onClick={() => {
                    handleNavigation({
                      module: "Transactions",
                      path: "/learn/learning-center/transactions-add-income",
                    });
                    props.scrollToTop();
                  }}
                >
                  Learn more
                </p>
              </div>
            </div>
            <div className="ln-section-container2-grid4">
              <div className="ln-section-container2-grid4-img">
                <img src={DashboardIcon} alt=""/>
              </div>
              <div className="ln-section-container2-grid4-title">
                <p>Dashboard</p>
              </div>
              <div className="ln-section-container2-grid4-description">
                <p>
                  Gain insights into your financial health at a glance, track
                  spending trends, and monitor progress towards your budgeting
                  goals.
                </p>
              </div>
              <div className="ln-section-container2-grid4-description-learn-more">
                <p
                  onClick={() => {
                    handleNavigation({
                      module: "Dashboard",
                      path: "/learn/learning-center/dashboard-summary",
                    });
                    props.scrollToTop();
                  }}
                >
                  Learn more
                </p>
              </div>
            </div>
            <div className="ln-section-container2-grid5">
              <div className="ln-section-container2-grid5-img">
                <img src={ReportsIcon} alt=""/>
              </div>
              <div className="ln-section-container2-grid5-title">
                <p>Reports</p>
              </div>
              <div className="ln-section-container2-grid5-description">
                <p>
                  Explore Reports to uncover detailed analyses of your financial
                  activities, empowering you to make informed decisions.
                </p>
              </div>
              <div className="ln-section-container2-grid5-description-learn-more">
                <p
                  onClick={() => {
                    handleNavigation({
                      module: "Reports",
                      path: "/learn/learning-center/reports-spending-analysis",
                    });
                    props.scrollToTop();
                  }}
                >
                  Learn more
                </p>
              </div>
            </div>
            <div className="ln-section-container2-grid6">
              <div className="ln-section-container2-grid6-img">
                <img src={PayeesIcon} alt=""/>
              </div>
              <div className="ln-section-container2-grid6-title">
                <p>Payees</p>
              </div>
              <div className="ln-section-container2-grid6-description">
                <p>
                  Explore how categorizing payees streamlines expense tracking,
                  empowering you to manage your finances more effectively.
                </p>
              </div>
              <div className="ln-section-container2-grid6-description-learn-more">
                <p
                  onClick={() => {
                    handleNavigation({
                      module: "Payees",
                      path: "/learn/learning-center/payees-add-payee",
                    });
                    props.scrollToTop();
                  }}
                >
                  Learn more
                </p>
              </div>
            </div>
            <div className="ln-section-container2-grid7">
              <div className="ln-section-container2-grid7-img">
                <img src={SettingsIcon} alt=""/>
              </div>
              <div className="ln-section-container2-grid7-title">
                <p>Settings</p>
              </div>
              <div className="ln-section-container2-grid7-description">
                <p>
                  Customize your app experience by adjusting preferences to
                  tailor the app to your needs and enhance your overall user
                  experience.
                </p>
              </div>
              <div className="ln-section-container2-grid7-description-learn-more">
                <p
                  onClick={() => {
                    handleNavigation({
                      module: "Settings",
                      path: "/learn/learning-center/settings-budget-settings",
                    });
                    props.scrollToTop();
                  }}
                >
                  Learn more
                </p>
              </div>
            </div>
            <div className="ln-section-container2-grid8">
              <div className="ln-section-container2-grid8-img">
                <img src={ProfileIcon} alt=""/>
              </div>
              <div className="ln-section-container2-grid8-title">
                <p>Profile</p>
              </div>
              <div className="ln-section-container2-grid8-description">
                <p>
                  Manage your user personal information to reflect your
                  preferences and maintain control over your account details
                  effortlessly.
                </p>
              </div>
              <div className="ln-section-container2-grid8-description-learn-more">
                <p
                  onClick={() => {
                    handleNavigation({
                      module: "Profile",
                      path: "/learn/learning-center/profile-edit-profile-details",
                    });
                    props.scrollToTop();
                  }}
                >
                  Learn more
                </p>
              </div>
            </div>
            <div className="ln-section-container2-grid9">
              <div className="ln-section-container2-grid9-img">
                <img src={OthersIcon} alt=""/>
              </div>
              <div className="ln-section-container2-grid9-title">
                <p>Others</p>
              </div>
              <div className="ln-section-container2-grid9-description">
                <p>
                  Lorem Neque porro quisquam est qui dolorem ipsum quia dolor
                  sit amet, consectetur, adipisci velit ipsum quia dolor sit
                  amet
                </p>
              </div>
              <div className="ln-section-container2-grid9-description-learn-more">
                <p
                  // onClick={() => {
                  //   handleNavigation({
                  //     module: "Budget",
                  //     path: "/learn/learning-center/budget-add-category-group",
                  //   });
                  //   props.scrollToTop();
                  // }}
                >
                  Learn more
                </p>
              </div>
            </div>
          </div>
          <div className="learn-go-to-top">
            <div
              className="go-to-page-top"
              onClick={() => {
                props.scrollToTop();
              }}
            ></div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Learn;
