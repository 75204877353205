import React from 'react'
import "./SearchPayee.css"
const SearchPayee = (props) => {
  
  return (
    <div className="search-payee-main-container">
      <div className="search-payee-breadcrumb">
        <p>Payees</p>
        <p> &gt; </p>
        <p>Search Payee</p>
      </div>
      <div className="search-payee-title-div">
        <p>How to search for a payee in Best Budget</p>
      </div>
      <div className="search-payee-section-container1">
        <div className="search-payee-section-container1-description">
          <p>1. Tap on the search field.</p>
        </div>
        <div className="search-payee-section-container1-img1-div">
          <div className="search-payee-section-container1-img1"></div>
        </div>
      </div>
      <div className="search-payee-section-container2">
        <div className="search-payee-section-container2-description">
          <p>
          2. Type the keyword you want to search for. 
          </p>
          <p>
          3. Press Enter to initiate the search.
          </p>
        </div>
        <div className="search-payee-section-container2-img1-div">
          <div className="search-payee-section-container2-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  )
}

export default SearchPayee