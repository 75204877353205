import React from "react";
import "./IndividualAllocation.css";
const IndividualAllocation = (props) => {
   return (
    <div className="individual-allocation-main-container">
      <div className="individual-allocation-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Individual Allocation</p>
      </div>
      <div className="individual-allocation-title-div">
        <p>How to allocate amount to a category in Best Budget</p>
      </div>
      <div className="individual-allocation-section-container1">
        <div className="individual-allocation-section-container1-description">
          <p>
            1. Locate the desired category you wish to allocate an amount to.
          </p>
          <p>2. Click on the respective allocated amount to enter edit mode.</p>
        </div>
        <div className="individual-allocation-section-container1-img1-div">
          <div className="individual-allocation-section-container1-img1"></div>
        </div>
      </div>
      <div className="individual-allocation-section-container2">
        <div className="individual-allocation-section-container2-description">
          <p>3. Allocate the desired amount.</p>
        </div>
        <div className="individual-allocation-section-container2-img1-div">
          <div className="individual-allocation-section-container2-img1"></div>
        </div>
      </div>     

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default IndividualAllocation;
