import React from "react";
import "./EditCateGroup.css";
const EditCateGroup = (props) => {
  
  return (
    <div className="edit-cate-group-main-container">
      <div className="edit-cate-group-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Edit Category Group</p>
      </div>
      <div className="edit-cate-group-title-div">
        <p>How to Edit a Category Group in Best Budget</p>
      </div>
      <div className="edit-cate-group-section-container1">
        <div className="edit-cate-group-section-container1-description">
          <p>
            1. On your Best Budget App, open Budget page and tap on “Manage
            Categories” icon located on the top right corner.
          </p>
        </div>
        <div className="edit-cate-group-section-container1-img1-div">
          <div className="edit-cate-group-section-container1-img1"></div>
        </div>
      </div>
      <div className="edit-cate-group-section-container2">
        <div className="edit-cate-group-section-container2-description">
          <p>
            2. Locate the category group field you wish to edit. Click on the
            field to enable editing mode.
          </p>
          <p> 3. Edit the name of the category group as desired.</p>
          <p>
            4. After making changes, click on the "Done" button to save the
            changes.
          </p>
        </div>
        <div className="edit-cate-group-section-container2-img1-div">
          <div className="edit-cate-group-section-container2-img1"></div>
        </div>
      </div>
      

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditCateGroup;
