import React from "react";
import "./ReconcileAccount.css";
const ReconcileAccount = (props) => {
  
  return (
    <div className="reconcile-account-main-container">
      <div className="reconcile-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Reconcile Account</p>
      </div>
      <div className="reconcile-account-title-div">
        <p>How to Reconcile an account in Best Budget</p>
      </div>
      <div className="reconcile-account-section-container1">
        <div className="reconcile-account-section-container1-description">
          <p>
            1. Open the Best Budget App and navigate to the detailed page of
            your desired account. Tap on the "Menu" icon located in the top
            right corner of the screen.
          </p>
          <p> 2. Select the 'Reconcile' option from the menu.</p>
        </div>
        <div className="reconcile-account-section-container1-img1-div">
          <div className="reconcile-account-section-container1-img1"></div>
        </div>
      </div>
      <div className="reconcile-account-section-container2">
        <div className="reconcile-account-section-container2-description">
          <p>
            3. Check for any pending transactions and proceed by clicking on
            'Proceed' from the pop-up to clear all pending transactions.
          </p>
        </div>
        <div className="reconcile-account-section-container2-img1-div">
          <div className="reconcile-account-section-container2-img1"></div>
        </div>
      </div>
      <div className="reconcile-account-section-container3">
        <div className="reconcile-account-section-container3-description">
          <p>
            4. In the subsequent pop-up window, verify whether your actual
            account balance is accurately displayed.
          </p>
          <p>
            {" "}
            5. If the displayed balance matches your actual balance, click on
            'Yes'. If not, click on 'No'.
          </p>
        </div>
        <div className="reconcile-account-section-container3-img1-div">
          <div className="reconcile-account-section-container3-img1"></div>
        </div>
      </div>
      <div className="reconcile-account-section-container4">
        <div className="reconcile-account-section-container4-description">
          <p>
            6. If the actual balance differs from the balance shown in the Best
            Budget, enter your actual account balance.
          </p>
        </div>
        <div className="reconcile-account-section-container4-img1-div">
          <div className="reconcile-account-section-container4-img1"></div>
        </div>
      </div>
      <div className="reconcile-account-section-container5">
        <div className="reconcile-account-section-container5-description">
          <p>
            7. In case of a difference in account balance, a reconcile
            adjustment transaction will be automatically posted to reconcile the
            difference between the actual balance and the previous balance.
            Click on 'Submit' to confirm.
          </p>
        </div>
        <div className="reconcile-account-section-container5-img1-div">
          <div className="reconcile-account-section-container5-img1"></div>
        </div>
      </div>
      <div className="reconcile-account-section-container6">
        <div className="reconcile-account-section-container6-description">
          <p>
            8. Once the account is successfully reconciled, tap on the menu icon
            at the top right corner of the screen and select 'Hide Reconciled'
            to conceal/Hide all reconciled transactions.
          </p>
        </div>
        <div className="reconcile-account-section-container6-img1-div">
          <div className="reconcile-account-section-container6-img1"></div>
        </div>
      </div>
      <div className="reconcile-account-section-container7">
        <div className="reconcile-account-section-container7-description">
          <p>
            9. To reveal all hidden transactions, again tap on the menu icon at
            the top right corner of the screen and select 'Show Reconciled'.
          </p>
        </div>
        <div className="reconcile-account-section-container7-img1-div">
          <div className="reconcile-account-section-container7-img1"></div>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default ReconcileAccount;
