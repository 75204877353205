import React from "react";
import "./EditAccount.css";
const EditAccount = (props) => {
  
  return (
    <div className="edit-account-main-container">
      <div className="edit-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Edit Account</p>
      </div>
      <div className="edit-account-title-div">
        <p>How to edit an account in Best Budget</p>
      </div>
      <div className="edit-account-section-container1">
        <div className="edit-account-section-container1-description">
          <p>
            1. Click on the account field for the account you wish to edit to
            access its details.
          </p>
        </div>
        <div className="edit-account-section-container1-img1-div">
          <div className="edit-account-section-container1-img1"></div>
        </div>
      </div>
      <div className="edit-account-section-container2">
        <div className="edit-account-section-container2-description">
          <p>
            2. On the account details page, tap on the "Menu" icon located in
            the top right corner.
          </p>
          <p>3. From the menu options, select "Edit Account".</p>
        </div>
        <div className="edit-account-section-container2-img1-div">
          <div className="edit-account-section-container2-img1"></div>
        </div>
      </div>
      <div className="edit-account-section-container3">
        <div className="edit-account-section-container3-description">
          <p>
            4. You can now edit the 'Account name', 'Current Balance', and
            'Account notes' as needed. (Note that the 'Account type' cannot be
            edited).
          </p>
        </div>
        <div className="edit-account-section-container3-img1-div">
          <div className="edit-account-section-container3-img1"></div>
        </div>
      </div>
      
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditAccount;
