import React from "react";
import "./ServiceTerms.css";
import Footer from "../Footer";
const ServiceTerms = (props) => {
  
  return (
    <React.Fragment>
      <div className="service-terms-main-container">
        <div className="st-actual-content-container">
          <div className="st-first-section">
            <div className="st-heading1">
              <p>Terms of Service</p>
            </div>
            <div className="st-last-updated-div">
              <p>Last updated: 1st April 2024</p>
            </div>
            <div className="st-description1">
              <p>
                <strong>“Best Budget”</strong> is a service provided by CREXIN
                Technologies Private Limited (“CREXIN Technologies”), an
                Indian-based company located in Hyderabad. As a personal finance
                application, “Best Budget” assists households and individuals in
                effectively managing their finances.
              </p>
            </div>
          </div>
          <div className="st-sub-section1">
            <h3 className="st-sub-section1-title">1. Agreeing to the Terms</h3>
            <div className="st-sub-section1-description">
              <p>
                By utilizing the information, tools, features, and functionality
                provided on bestbudget.in and its sub-domains (collectively
                referred to as “bestbudget.in”), you are consenting to adhere to
                the following terms and conditions (“Terms of Service”). If you
                intend to become a member and utilize the Service, it is
                imperative that you review this Agreement and signify your
                acceptance during the Registration process.
              </p>
              <p>
                If you are not of legal age in your country of residence to form
                a binding contract with ‘Best Budget,’ you are prohibited from
                using the Service and from accepting this Agreement.
              </p>

              <p>
                Failure to comply with any of the terms outlined below will lead
                to the termination of your Account.
              </p>
            </div>
          </div>
          <div className="st-sub-section2">
            <h3 className="st-sub-section2-title">
              2. Explanation of the Service
            </h3>
            <div className="st-sub-section2-description">
              <p>
                The Service is a personal finance information management
                platform available on ‘bestbudget.in’. It enables members to
                consolidate and monitor their financial data. Additionally,
                based on this information, the Service may offer insights
                regarding third-party products or services.
              </p>
              <p>
                {" "}
                ‘Best Budget’ provides the Service to assist you in organizing
                and managing your finances. It’s essential to note that the
                Service is not meant to offer legal, tax, or financial advice.
              </p>
            </div>
          </div>
          <div className="st-sub-section3">
            <h3 className="st-sub-section3-title">
              3. Your Privacy and Personal Data
            </h3>
            <div className="st-sub-section3-description">
              <p>
                Best Budget will not sell, exchange, or disclose any of your
                information or financial data to a third party without your
                explicit consent or as mandated by law, court, or governmental
                order. For details regarding Best Budget’s data protection
                practices, please refer to Best Budget’s Privacy and Security
                Policy, which is hereby included in this Agreement.
              </p>
              <p>
                The policy may be revised periodically at our discretion, with
                changes becoming effective upon posting to the site.
              </p>
            </div>
          </div>
          <div className="st-sub-section4">
            <h3 className="st-sub-section4-title">
              4. Registering for Best Budget
            </h3>
            <div className="st-sub-section4-description">
              <p>
                You are required to register your account manually, and
                automated registrations by "bots" or other means are not
                allowed. Furthermore, you must be at least 18 years old to
                register for 'Best Budget'.
              </p>
              <p>
                You agree and acknowledge that you are accountable for
                safeguarding the confidentiality of your password or OTP
                (one-time password), which, along with your email address,
                grants you access to the Service. By furnishing us with your
                email address, you consent to receiving all necessary
                notifications electronically at that email address. It is your
                duty to update or modify that address, as necessary.
              </p>
              <p>
                Notifications will be presented in HTML format, or in plain text
                if your system doesn't support HTML, within the body of the
                email or via a link directing you to the relevant page on our
                site. This page can be accessed through any standard,
                commercially available internet browser.{" "}
              </p>
              <p>
                If you become aware of any unauthorized use of your Registration
                Information, you consent to promptly notify 'Best Budget' at the
                email address:{" "}
                <a href="mailto:support@bestbudget.in">
                  support@bestbudget.in.
                </a>{" "}
              </p>
            </div>
          </div>
          <div className="st-sub-section5">
            <h3 className="st-sub-section5-title">
              5. Your Utilisation of the Service
            </h3>
            <div className="st-sub-section5-description">
              <p>
                Your access and usage of ‘bestbudget.in’ may be occasionally
                interrupted for various reasons, including, but not limited to,
                equipment malfunctions, periodic updates, maintenance, or
                repairs of ‘bestbudget.in,’ or other actions that ‘Best Budget’
                may choose to undertake at its sole discretion.
              </p>
            </div>
          </div>
          <div className="st-sub-section6">
            <h3 className="st-sub-section6-title">
              6. Online Alerts and Disclaimer
            </h3>
            <div className="st-sub-section6-description">
              <p>
                ‘Best Budget' may occasionally offer automatic alerts and
                voluntary account-related alerts. Automatic alerts may be sent
                to you following specific online changes made to your 'Best
                Budget' account, such as modifications to your Registration
                Information.
              </p>
              <p>
                Voluntary account alerts may be enabled by default as a feature
                of the Service. You can then customize, deactivate, or
                reactivate them according to your preferences. However, core
                account alerts, such as subscription plan changes and email
                changes, cannot be deactivated.
              </p>
              <p>
                You acknowledge and consent that alerts delivered to you via the
                Service might experience delays or hindrances due to various
                factors. While 'Best Budget' endeavours to furnish alerts
                promptly with precise information, we do not assure the
                timeliness or accuracy of any alert's content.
              </p>
              <p>
                Additionally, you concur that 'Best Budget' bears no
                responsibility for delays, non-delivery, or misdirected delivery
                of alerts, content errors within alerts, or any actions, whether
                taken or omitted, by you or any third party based on an alert.
              </p>
            </div>
          </div>
          <div className="st-sub-section7">
            <h3 className="st-sub-section7-title">7. Rights You Grant to Us</h3>
            <div className="st-sub-section7-description">
              <p>
                By submitting information, data, passwords, usernames, login
                information, and other content to Best Budget through the
                Service, you are granting a license for that content to Best
                Budget, solely for the purpose of providing the Service. Best
                Budget may utilize and store the content, but solely for the
                purpose of providing the Service to you.
              </p>
              <p>
                By submitting this content to Best Budget, you affirm that you
                have the right to submit it to Best Budget for use for this
                purpose, without any obligation for Best Budget to pay any fees
                or other limitations.
              </p>
            </div>
          </div>
          <div className="st-sub-section8">
            <h3 className="st-sub-section8-title">
              8. Best Budget’s Intellectual Property Rights
            </h3>
            <div className="st-sub-section8-description">
              <p>
                The contents of ‘Best Budget.in’, including its “look and feel”
                (such as text, graphics, images, logos, and button icons),
                photographs, editorial content, notices, software (including
                HTML-based computer programs), and other material, are protected
                under both Indian and other applicable copyright, trademark, and
                other laws. Any distribution, reprint, or electronic
                reproduction of any content from ‘Best Budget.in’ in whole or in
                part for any other purpose is expressly prohibited without our
                prior written consent.
              </p>
            </div>
          </div>
          <div className="st-sub-section9">
            <h3 className="st-sub-section9-title">
              9. Access and Interference
            </h3>
            <div className="st-sub-section9-description">
              <p>
                You affirm that you will not access or utilize the service in
                any unlawful manner, for any unlawful purpose, or in
                contravention of these terms and conditions or applicable laws,
                rules, and regulations. You also agree that you will be
                accountable for all usage of the service through your account.
              </p>
              <p>
                You agree not to:
                <ol type="1">
                  <li>
                    You agree not to utilize any robot, spider, scraper, deep
                    link, or similar automated data gathering or extraction
                    tools, program, algorithm, or methodology to access,
                    acquire, copy, or monitor bestbudget.in or any portion of
                    bestbudget.in without Best Budget’s express written consent,
                    which may be withheld at Best Budget’s sole discretion.{" "}
                  </li>
                  <li>
                    You are prohibited from attempting to decipher, decompile,
                    disassemble, or reverse-engineer any of the software that
                    constitutes or forms a part of bestbudget.in or the Service.{" "}
                  </li>
                </ol>
              </p>
            </div>
          </div>
          <div className="st-sub-section10">
            <h3 className="st-sub-section10-title">10. Posting Feedback</h3>
            <div className="st-sub-section10-description">
              <p>
                As an integral aspect of the Service, Best Budget enables
                members to post feedback. By engaging in content posting, you
                agree to adhere to specific rules. It is your responsibility to
                ensure that all content you submit to bestbudget.in complies
                with these rules.
              </p>
              <p>
                By submitting content to us, you affirm that you possess all
                necessary rights and hereby grant us a perpetual, worldwide,
                non-exclusive, royalty-free, sublicensable, and transferable
                license to utilize, reproduce, distribute, prepare derivative
                works of, modify, display, and perform all or any portion of the
                content in connection with bestbudget.in and our business. This
                includes, without limitation, promoting and redistributing part
                or all of the site (and derivative works thereof) in any media
                formats and through any media channels.
              </p>
            </div>
          </div>
          <div className="st-sub-section11">
            <h3 className="st-sub-section11-title">
              11. Disclaimer of Representations and Warranties
            </h3>
            <div className="st-sub-section11-description">
              <p>
                ‘BEST BUDGET’ OFFERS THE SERVICE ON AN “AS IS” AND “AS
                AVAILABLE” BASIS AND DOES NOT PROVIDE ANY WARRANTIES OF ANY
                KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. TO THE
                FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY
                EXPLICITLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
              </p>
            </div>
          </div>
          <div className="st-sub-section12">
            <h3 className="st-sub-section12-title">
              12. Not a Financial Planner, Broker, or Tax Advisor
            </h3>
            <div className="st-sub-section12-description">
              <p>
                NEITHER 'BEST BUDGET' NOR THE SERVICE IS DESIGNED TO PROVIDE
                LEGAL, TAX, OR FINANCIAL ADVICE. 'BEST BUDGET' IS NOT A
                FINANCIAL PLANNER, BROKER, OR TAX ADVISOR.
              </p>
              <p>
                The Service is solely intended to aid you in organizing and
                making financial decisions and is comprehensive in nature. Your
                individual financial circumstances are unique, and any
                information or advice obtained through the Service may not be
                suitable for your situation.
              </p>
              <p>
                Therefore, prior to making any definitive decisions or executing
                any financial strategies, it is advisable to seek additional
                information and advice from your accountant or other financial
                advisors who are fully acquainted with your individual
                circumstances.
              </p>
            </div>
          </div>
          <div className="st-sub-section13">
            <h3 className="st-sub-section13-title">
              13. Limitations on Best Budget’s Liability
            </h3>
            <div className="st-sub-section13-description">
              <p>
                ‘BEST BUDGET’ SHALL NOT, UNDER ANY CIRCUMSTANCES, BE HELD
                RESPONSIBLE OR LIABLE TO YOU OR ANY THIRD PARTY, WHETHER IN
                CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE,
                FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY,
                LIQUIDATED, OR PUNITIVE DAMAGES. THIS INCLUDES, BUT IS NOT
                LIMITED TO, LOSS OF PROFIT, REVENUE, OR BUSINESS, ARISING IN
                WHOLE OR IN PART FROM YOUR ACCESS TO ‘BEST BUDGET’.COM, YOUR USE
                OF THE SERVICE, OR THIS AGREEMENT, EVEN IF ‘BEST BUDGET’ HAS
                BEEN MADE AWARE OF THE POTENTIAL FOR SUCH DAMAGES.
              </p>
            </div>
          </div>
          <div className="st-sub-section14">
            <h3 className="st-sub-section14-title">
              14. Your Indemnification of ‘Best Budget’
            </h3>
            <div className="st-sub-section14-description">
              <p>
                You agree to defend, indemnify, and hold harmless ‘Best Budget’,
                its officers, directors, employees, and agents from and against
                any and all claims, damages, obligations, losses, liabilities,
                costs, or debt, demands, and expenses (including but not limited
                to attorneys’ fees) arising from:
                <ol type="1">
                  <li>
                    Your use of and access to the Service or any Content that
                    you post thereon.
                  </li>
                  <li>
                    Your violation of any term of this Agreement or your
                    representations and warranties set forth above; or
                  </li>
                  <li>
                    Your violation of any third-party right, including without
                    limitation any copyright, property, or privacy right.
                  </li>
                </ol>
              </p>
            </div>
          </div>
          <div className="st-sub-section15">
            <h3 className="st-sub-section15-title">
              15. Modifications and Other Provisions
            </h3>
            <div className="st-sub-section15-description">
              <p>
                ‘Best Budget’ reserves the right to periodically modify this
                Agreement. Any changes to this Agreement will be posted on the
                bestbudget.in website, and the Agreement will always indicate
                the date it was last revised. By continuing to use the Service
                after these modifications are posted, you are deemed to accept
                and agree to be bound by the updated Agreement.
              </p>
              <p>
                All commitments, agreements, representations, and warranties
                made in this Agreement shall continue to be valid and effective
                following your acceptance of this Agreement and even after the
                termination of this Agreement.
              </p>
              <p>
                This Agreement represents the comprehensive understanding and
                agreement between you and 'Best Budget' concerning the subject
                matter herein, superseding all prior agreements.
              </p>
            </div>
          </div>
          <div className="st-sub-section16">
            <h3 className="st-sub-section16-title">16. Privacy</h3>
            <div className="st-sub-section16-description">
              <p>
                Use of the Services is also governed by our Privacy Policy
                available at {" "}
                <a href="https://www.bestbudget.in/privacy-policy">
                  https://www.bestbudget.in/privacy-policy
                </a>
              </p>
            </div>
          </div>
          <div className="st-sub-section17">
            <h3 className="st-sub-section17-title">
              17. Subscription Terms and Account Termination
            </h3>
            <div className="st-sub-section17-description">
              <p>
                All Services are provided on a recurring basis through the Best
                Budget Application and/or website, and the Google Play Store.
                Periodic subscriptions will be automatically renewed by Best
                Budget unless you deactivate a service or opt out of automatic
                renewal.
              </p>
              <p>
                You can disable any service at any time by accessing the
                subscription page in your account and following the instructions
                provided for disabling a Service. Disabling a service will take
                effect after any existing paid periods expire. When you disable
                a service, no charges for that service will appear in your
                subscription fee for the next billing cycle.
              </p>
              <p>
                Requests to disable any service or cancel your account must be
                processed before 5 pm (IST) two days before your monthly billing
                cycle to avoid incurring a subsequent charge for your
                Subscription Fee.
              </p>
              <p>
                Disabling any Service may result in the loss of content,
                features, or capacity associated with your account. Cancelling
                your account will lead to the immediate loss of all Content,
                features, and capacity associated with your account. This
                information cannot be recovered, and Best Budget assumes no
                liability for any such loss.
              </p>
              <p>
                Best Budget, at its sole discretion, reserves the right to
                terminate this Agreement, your account, and all current or
                future use of the Services, for any reason, at any time.
              </p>
              <p>
                Termination of your account involves the revocation of your
                access to all Services and the forfeiture of all User Content in
                your account. Best Budget also reserves the right to decline
                services to anyone for any reason at any time.
              </p>
            </div>
          </div>
          <div className="st-go-to-top">
            <div
              className="go-to-page-top"
              onClick={() => {
                props.scrollToTop();
              }}
            ></div>
          </div>
        </div>       
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default ServiceTerms;
